import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import './Banner.scss';

import Twemoji from 'react-twemoji';
import { string } from 'prop-types';

const boatSinkingAnimation = '12s boat-sink ease-in-out';

export default function Banner({ screen }) {
  const boatRef = useRef();
  const boatSinking = useRef();
  boatSinking.current = false;

  const [coconutFalling, setCoconutFalling] = useState(false);

  const onBoatClickHandler = () => {
    if (!boatSinking.current) {
      boatSinking.current = true;
      boatRef.current.style.animation = boatSinkingAnimation;
      setTimeout(() => {
        boatRef.current.style.animation =
          '5s boat alternate ease-in-out infinite';
        setTimeout(() => {
          boatSinking.current = false;
        }, 3000);
      }, 12000);
    }
  };

  const onPalmTreeClickHandler = () => {
    if (!coconutFalling) {
      setCoconutFalling(true);
      setTimeout(() => {
        setCoconutFalling(false);
      }, 6000);
    }
  };

  return (
    <div
      id="banner"
      className={classNames({
        'firefox-fix': navigator.userAgent.includes('Firefox'),
      })}
    >
      <div className="drawing">
        <div className="stars">
          <div className="star star-1" />
          <div className="star star-2" />
          <div className="star star-3" />
          <div className="star star-4" />
          <div className="star star-5" />
          <div className="star star-6" />
          <div className="star star-7" />
          <div className="star star-8" />
          <div className="star star-9" />
          <div className="star star-10" />
          <div className="star star-11" />
          <div className="star star-12" />
        </div>
        <div className="stars-2">
          <div className="star star-1" />
          <div className="star star-2" />
          <div className="star star-3" />
          <div className="star star-4" />
          <div className="star star-5" />
        </div>
        <div className="stars-3">
          <div className="star star-1" />
          <div className="star star-2" />
          <div className="star star-3" />
        </div>
        {screen === 'default' && (
          <div className="sun-and-moon">
            <div className="sun" />
            <div className="moon" />
          </div>
        )}
        {screen !== 'tiny' && screen !== 'mobile' && (
          <>
            <div className="island" />
            {coconutFalling && (
              <Twemoji
                options={{ className: 'coconut', folder: 'svg', ext: '.svg' }}
              >
                <span role="img" aria-label="Coconut">
                  🥥
                </span>
              </Twemoji>
            )}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              className="palm-tree-container"
              onClick={onPalmTreeClickHandler}
            >
              <Twemoji
                options={{ className: 'palm-tree', folder: 'svg', ext: '.svg' }}
              >
                <span role="img" aria-label="Palm tree">
                  🌴
                </span>
              </Twemoji>
            </div>
          </>
        )}
        <div className="wave wave-bg" />
        {screen !== 'tiny' && (
          /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
          <div className="boat" ref={boatRef} onClick={onBoatClickHandler}>
            <div className="chimney" />
            <div className="cabin" />
            <div className="window window-1" />
            <div className="window window-2" />
            <div className="cabin-rear" />
            <div className="buoy">
              <div className="line line-1" />
              <div className="line line-2" />
              <div className="inner" />
            </div>
            <div className="base" />
          </div>
        )}
        <div className="wave wave-fg" />
      </div>
      <div className="content">
        <h1>Jordy Rymenants</h1>
        <h2>Web Developer, Software Developer, Designer</h2>
      </div>
    </div>
  );
}

Banner.propTypes = {
  screen: string.isRequired,
};
