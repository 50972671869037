import React, { useState, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';

import Header from './components/Header/Header';
import About from './components/About/About';
import Projects from './components/Projects/Projects';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';

export function App() {
  const [screen, setScreen] = useState('default');

  useEffect(() => {
    if (window.innerWidth <= 400) {
      setScreen('tiny');
    } else if (window.innerWidth <= 640) {
      setScreen('mobile');
    } else if (
      window.innerWidth < 1024 ||
      window.innerWidth / 3 <= window.innerHeight / 4
    ) {
      setScreen('minimal');
    } else {
      setScreen('default');
    }
  }, []);
  return (
    <>
      <Header screen={screen} />
      <main>
        <About screen={screen} />
        <Projects />
        <Contact screen={screen} />
      </main>
      <Footer />
    </>
  );
}

export default hot(App);
