import Img1 from '../images/soft_cod4u_1.jpg';
import Img2 from '../images/soft_cod4u_2.jpg';

export default {
  id: 'cod4-unleashed',
  category: 'Software Development',
  title: 'Call of Duty 4: Unleashed',
  description: [
    'CoD4U is an open source effort to extend the limited functionality of the stock dedicated server binaries for Call of Duty 4: Modern Warfare. The extension is written in C and is a fork of the no longer supported CoD4X17a.',
    "The project started out as a means to bring gamemodes such as Source's surf mode to CoD4 and has been used to create several mods since. Some of the new functionality includes: HTTP requests, input polling, velocity manipulation, additional client info requests, silent admin commands ...",
  ],
  thumb: Img1,
  images: [Img1, Img2],
  links: {
    github: 'https://github.com/Raid-Gaming/CoD4-Unleashed-Server',
    download: 'https://github.com/Raid-Gaming/CoD4-Unleashed-Server/releases',
  },
};
