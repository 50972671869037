import Img1 from '../images/raid_servers_1.jpg';
import Img2 from '../images/raid_servers_2.jpg';
import Img3 from '../images/raid_servers_3.jpg';
import Img4 from '../images/raid_servers_4.jpg';
import Img5 from '../images/raid_servers_5.jpg';
import Img6 from '../images/raid_servers_6.jpg';
import Img7 from '../images/raid_servers_7.jpg';

export default {
  id: 'raid-servers',
  category: 'Web Development',
  title: 'Raid Gaming Servers App',
  description: [
    "A Vue.js application that provides live statistics about Raid Gaming's game servers. It contains leaderboards for various servers, live player data, a client and ban list, profile pages...",
    'The frontend runs on Vue.js with Vue Router used for routing and Vuex for state management. The backend is an Express.js REST API connected to a MySQL database.',
    'The application allows users to sign in using their forum account (running IP.Board 4) and shows their personalised statistics where applicable.',
  ],
  thumb: Img1,
  images: [Img1, Img2, Img3, Img4, Img5, Img6, Img7],
  links: { demo: 'https://raid-gaming.net' },
};
