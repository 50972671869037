import React from 'react';

import { Slide } from 'react-slideshow-image';

import CloseIcon from '@material-ui/icons/Close';
import EarthIcon from 'mdi-material-ui/Earth';
import GitHubIcon from '@material-ui/icons/GitHub';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import './ProjectDialog.scss';
import { shape, string, arrayOf, func, number } from 'prop-types';

const Slideshow = ({ images }) => {
  return (
    <div className="slide-container">
      <Slide duration={8000} transitionDuration={500} infinite>
        {images.map(image => (
          <img key={image.id} src={image.image} alt={`Slide ${image.id}`} />
        ))}
      </Slide>
    </div>
  );
};

Slideshow.propTypes = {
  images: arrayOf(
    shape({
      id: number,
      image: string,
    })
  ).isRequired,
};

export default function ProjectDialog({ project, closeHandler }) {
  const images = Object.values(project.images).map((image, id) => ({
    id,
    image,
  }));

  return (
    <div className="project-dialog">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className="outside-area" onClick={closeHandler} />
      <div className="project-container">
        <div className="lines">
          <div className="line line--1" />
          <div className="line line--2" />
          <div className="line line--3" />
          <div className="line line--4" />
          <div className="line line--5" />
          <div className="line line--6" />
        </div>

        <button type="button" className="close-button" onClick={closeHandler}>
          <CloseIcon />
        </button>

        <div className="project-info">
          <h2>{project.title}</h2>

          <article className="description">
            <h3>Description</h3>
            {project.description.map(desc => (
              <p key={desc}>{desc}</p>
            ))}
          </article>

          <article className="images">
            <h3>Images</h3>
            <Slideshow images={images} />
          </article>

          {project.video && (
            <article className="video">
              <h3>Video</h3>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: project.video }} />
            </article>
          )}

          <div className="links">
            {project.links.demo && (
              <a
                href={project.links.demo}
                target="_blank"
                rel="noopener noreferrer"
              >
                <EarthIcon />
                Demo
              </a>
            )}
            {project.links.github && (
              <a
                href={project.links.github}
                target="_blank"
                rel="noopener noreferrer"
              >
                <GitHubIcon />
                GitHub
              </a>
            )}
            {project.links.download && (
              <a
                href={project.links.download}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CloudDownloadIcon />
                Download
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

ProjectDialog.propTypes = {
  project: shape({
    id: string,
    title: string,
    description: arrayOf(string),
    images: arrayOf(string),
  }).isRequired,
  closeHandler: func.isRequired,
};
