import Img1 from '../images/mod_risev2_1.jpg';
import Img2 from '../images/mod_risev2_2.jpg';
import Img3 from '../images/mod_risev2_3.jpg';
import Img4 from '../images/mod_risev2_4.jpg';
import Img5 from '../images/mod_risev2_5.jpg';

export default {
  id: 'waw-risev2',
  category: 'Mods',
  title: 'Zombie Rise V2',
  description: [
    'An expansion of the original "ZombieModding Map of the Year 2013" Rise. This new version was both bigger and better, including 5 new areas, a massive in-depth easter egg and a plethora of new weapons and features.',
  ],
  thumb: Img1,
  images: [Img1, Img2, Img3, Img4, Img5],
  video:
    '<iframe width="560" height="315" src="https://www.youtube.com/embed/WfUDWa186mg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
  links: {
    download: 'https://jordyrymenants.com/dl/risev2.zip',
  },
};
