import React, { useState } from 'react';
import Zoom from 'react-reveal/Zoom';

import ProjectThumb from './ProjectThumb';
import ProjectDialog from './ProjectDialog';

import projects from './projects/index';
import './Projects.scss';

export default function Projects() {
  const [project, setProject] = useState();

  const onThumbClickHandler = id => {
    setProject(projects[id]);
  };

  const closeDialogHandler = () => {
    setProject(null);
  };

  return (
    <div id="projects">
      <Zoom>
        <h3>Projects</h3>

        <div className="gallery">
          {Object.values(projects).map(p => (
            <ProjectThumb
              key={p.id}
              project={p}
              onClick={onThumbClickHandler}
            />
          ))}
        </div>
      </Zoom>
      {project && (
        <ProjectDialog project={project} closeHandler={closeDialogHandler} />
      )}
    </div>
  );
}
