import React from 'react';
import { shape, string } from 'prop-types';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import classNames from 'classnames';

import './Error.scss';

export default function Error({ error }) {
  return (
    <div
      className={classNames('error', {
        visible: error && error.message,
      })}
    >
      <div className="icon">
        <ErrorIcon />
      </div>
      <p>{(error && error.message) || ''}</p>
    </div>
  );
}

Error.propTypes = {
  error: shape({
    message: string.isRequired,
  }),
};

Error.defaultProps = {
  error: undefined,
};
