import React, { useState, useEffect, useRef } from 'react';
import Fade from 'react-reveal/Fade';
import Twemoji from 'react-twemoji';
import { string } from 'prop-types';

import Photo from './photo.jpg';
import Grass1 from './grass1.png';
import Grass2 from './grass2.png';
import './About.scss';

export default function About({ screen }) {
  const [scrolled, setScrolled] = useState(false);

  const squidRef = useRef();
  const squidFlipping = useRef();
  squidFlipping.current = false;

  useEffect(() => {
    window.addEventListener('scroll', () => setScrolled(true), {
      once: true,
      capture: true,
    });
  }, []);

  const onSquidClickHandler = () => {
    if (!squidFlipping.current) {
      squidFlipping.current = true;
      squidRef.current.style.animation = `.5s squid-flip ${
        Math.random() > 0.5 ? 'normal' : 'reverse'
      } linear`;
      setTimeout(() => {
        squidRef.current.style.animation = '';
        setTimeout(() => {
          squidFlipping.current = false;
        }, 1000);
      }, 1000);
    }
  };

  return (
    <div id="about">
      <Fade bottom when={scrolled}>
        <h3>About</h3>

        <div className="desc">
          {screen !== 'tiny' && screen !== 'mobile' && (
            <>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */}
              <div
                className="squid-container"
                onClick={onSquidClickHandler}
                ref={squidRef}
              >
                <Twemoji
                  options={{ className: 'squid', folder: 'svg', ext: '.svg' }}
                >
                  <span role="img" aria-label="Squid">
                    🦑
                  </span>
                </Twemoji>
              </div>

              <div className="photo-bubble">
                <img src={Photo} alt="Yours truly" />
                <div className="bubble bubble--1" />
                <div className="bubble bubble--2" />
                <div className="bubble bubble--3" />
              </div>
            </>
          )}

          <p>
            I started my journey into software development around{' '}
            <strong>8 years old</strong>, playing around with drag & drop game
            development tools. I wanted to create a large scale game of my own
            but after producing some prototypes, reality dawned on me. Creating
            something at that scale is not something I&apos;d be able to do on
            my own and joining a game development studio meant I couldn&apos;t
            realise <em>my</em> vision.
          </p>

          <p>
            Having realised game development might not be the best route to go
            down, I went to explore different paths. That is how I discovered
            the wonders of web development! Ever since I have been{' '}
            <strong>expanding my skill- and toolset</strong> as the web
            landscape continues to grow and evolve.
          </p>

          <p>
            Technology doesn&apos;t stand still and as a result, I&apos;ve
            worked with many different libraries and frameworks. From the newest
            kid on the block in the frontend world (Vue/Nuxt.js, React/Next.js,
            ...) to longstanding standards on the backend (Express, NestJS,
            MongoDB, SQL, ...) all written in <strong>TypeScript</strong>.
          </p>

          <p>
            For questions or business inquiries, please use the{' '}
            <strong>contact form</strong> below. I try to respond as soon as
            possible, usually within 24 hours.
          </p>
        </div>
      </Fade>
      <div className="seabed">
        <div className="treasure-chest">
          <div className="treasure-chest--lid" />
        </div>
        <Twemoji options={{ className: 'crab', folder: 'svg', ext: '.svg' }}>
          <span role="img" aria-label="Crab">
            🦀
          </span>
        </Twemoji>
        <Twemoji options={{ className: 'shell', folder: 'svg', ext: '.svg' }}>
          <span role="img" aria-label="Shell">
            🐚
          </span>
        </Twemoji>
        <img src={Grass1} alt="Seaweed" className="seaweed seaweed--1" />
        <img src={Grass2} alt="Seaweed" className="seaweed seaweed--2" />
      </div>
    </div>
  );
}

About.propTypes = {
  screen: string.isRequired,
};
