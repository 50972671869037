import React from 'react';

import LinkedInIcon from 'mdi-material-ui/Linkedin';
import GitHubIcon from '@material-ui/icons/GitHub';

import './Footer.scss';

export default function Footer() {
  return (
    <footer>
      <svg
        className="wave"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        preserveAspectRatio="none"
      >
        <path d="M0,192L40,186.7C80,181,160,171,240,181.3C320,192,400,224,480,208C560,192,640,128,720,138.7C800,149,880,235,960,261.3C1040,288,1120,256,1200,245.3C1280,235,1360,245,1400,250.7L1440,256L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z" />
      </svg>
      <div className="container">
        <p className="copyright">
          Copyright &copy; 2017-{new Date().getFullYear()} Jordy Rymenants
        </p>
        <div className="socials">
          <a
            href="https://www.linkedin.com/in/jordyrymenants/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon />
          </a>
          <a
            href="https://github.com/atrX"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GitHubIcon />
          </a>
        </div>
      </div>
    </footer>
  );
}
