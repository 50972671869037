import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import Fade from 'react-reveal/Fade';
import autosize from 'autosize';
import classNames from 'classnames';
import Twemoji from 'react-twemoji';
import { string } from 'prop-types';
import axios from 'axios';

import Error from './Error';

import './Contact.scss';

export default function Contact({ screen }) {
  const { handleSubmit, register, errors, reset } = useForm();
  const [focus, setFocus] = useState({
    name: false,
    email: false,
    subject: false,
    message: false,
  });

  useEffect(() => {
    autosize(document.querySelector('form .form-input textarea'));
  }, []);

  const submitHandler = async values => {
    try {
      await axios.post('https://jordyrymenants.com/api/contact.php', values);
      reset();
    } catch (err) {
      console.error(err);
    }
  };

  const focusHandler = e => {
    setFocus({
      ...focus,
      [e.target.name]: true,
    });
  };

  const blurHandler = e => {
    setFocus({
      ...focus,
      [e.target.name]: false,
    });
  };

  return (
    <div id="contact">
      {screen === 'default' && (
        <>
          <Twemoji options={{ className: 'skull', folder: 'svg', ext: '.svg' }}>
            <span role="img" aria-label="Skull">
              ☠️
            </span>
          </Twemoji>
          <Twemoji options={{ className: 'bone', folder: 'svg', ext: '.svg' }}>
            <span role="img" aria-label="Bone">
              🦴
            </span>
          </Twemoji>
        </>
      )}
      <svg
        className="wave"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        preserveAspectRatio="none"
      >
        <path d="M0,0L16,10.7C32,21,64,43,96,90.7C128,139,160,213,192,234.7C224,256,256,224,288,192C320,160,352,128,384,138.7C416,149,448,203,480,240C512,277,544,299,576,293.3C608,288,640,256,672,250.7C704,245,736,267,768,234.7C800,203,832,117,864,106.7C896,96,928,160,960,186.7C992,213,1024,203,1056,213.3C1088,224,1120,256,1152,240C1184,224,1216,160,1248,160C1280,160,1312,224,1344,240C1376,256,1408,224,1424,208L1440,192L1440,320L1424,320C1408,320,1376,320,1344,320C1312,320,1280,320,1248,320C1216,320,1184,320,1152,320C1120,320,1088,320,1056,320C1024,320,992,320,960,320C928,320,896,320,864,320C832,320,800,320,768,320C736,320,704,320,672,320C640,320,608,320,576,320C544,320,512,320,480,320C448,320,416,320,384,320C352,320,320,320,288,320C256,320,224,320,192,320C160,320,128,320,96,320C64,320,32,320,16,320L0,320Z" />
      </svg>
      <Fade bottom>
        <h3>Contact</h3>

        <form onSubmit={handleSubmit(submitHandler)} noValidate>
          <div
            className={classNames('form-input', {
              focus: focus.name,
            })}
          >
            <input
              name="name"
              type="text"
              placeholder="Name"
              ref={register({
                required: 'You must enter a name',
              })}
              onFocus={focusHandler}
              onBlur={blurHandler}
            />
            <Error error={errors.name} />
          </div>

          <div
            className={classNames('form-input', {
              focus: focus.email,
            })}
          >
            <input
              name="email"
              type="email"
              placeholder="Email address"
              ref={register({
                required: 'You must enter a valid email address',
                pattern: {
                  value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                  message: 'Invalid email address',
                },
              })}
              onFocus={focusHandler}
              onBlur={blurHandler}
            />
            <Error error={errors.email} />
          </div>

          <div
            className={classNames('form-input', {
              focus: focus.subject,
            })}
          >
            <input
              name="subject"
              type="text"
              placeholder="Subject"
              ref={register({ required: 'You must enter a subject' })}
              onFocus={focusHandler}
              onBlur={blurHandler}
            />
            <Error error={errors.subject} />
          </div>

          <div
            className={classNames('form-input', {
              focus: focus.message,
            })}
          >
            <textarea
              name="message"
              rows="5"
              placeholder="Message"
              ref={register({
                required: 'You must enter a message',
              })}
              onFocus={focusHandler}
              onBlur={blurHandler}
            />
            <Error error={errors.message} />
          </div>
          <button type="submit">Send</button>
        </form>
      </Fade>
    </div>
  );
}

Contact.propTypes = {
  screen: string.isRequired,
};
