import Img1 from '../images/mod_cod4_surf_1.jpg';
import Img2 from '../images/mod_cod4_surf_2.jpg';
import Img3 from '../images/mod_cod4_surf_3.jpg';
import Img4 from '../images/mod_cod4_surf_4.jpg';
import Img5 from '../images/mod_cod4_surf_5.jpg';

export default {
  id: 'cod4-surf',
  category: 'Mods',
  title: 'Call of Duty 4: Surf',
  description: [
    'The Call of Duty 4: Surf Mod brings a fan favourite gamemode from a variety of games powered by the Source engine to Call of Duty 4. The mod features smooth movement mechanics, online leaderboards, challenges and tonnes of customisation options.',
    'The challenge in making a mod such as surf in Call of Duty 4 lies in the lack of official support for user input detection. This issue was ultimately solved thanks to the CoD4X Dedicated Server project.',
  ],
  thumb: Img1,
  images: [Img1, Img2, Img3, Img4, Img5],
  video:
    '<iframe width="560" height="315" src="https://www.youtube.com/embed/pKDnu2IpT7Y" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
  links: {
    github: 'https://github.com/atrX/CoD4-Surf',
    download: 'https://www.moddb.com/mods/cod4-surf-mod/downloads',
  },
};
