import React from 'react';
import { func, shape, string } from 'prop-types';

import './ProjectThumb.scss';

export default function ProjectThumb({ project, onClick }) {
  return (
    <button
      type="button"
      className="project-thumb"
      onClick={() => onClick(project.id)}
    >
      <div className="planks">
        <div className="plank plank--1" />
        <div className="plank plank--2" />
        <div className="plank plank--3" />
        <div className="plank plank--4" />
        <img src={project.thumb} alt={project.title} />
        <div className="desc">
          <h5>{project.category}</h5>
          <p>{project.title}</p>
        </div>
        <div className="pin" />
        <div className="nail" />
      </div>
    </button>
  );
}

ProjectThumb.propTypes = {
  project: shape({
    id: string,
    category: string,
    title: string,
    thumb: string,
  }).isRequired,
  onClick: func.isRequired,
};
