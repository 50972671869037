import React from 'react';
import { string } from 'prop-types';

import Banner from './Banner';

export default function Header({ screen }) {
  return (
    <header>
      <Banner screen={screen} />
    </header>
  );
}

Header.propTypes = {
  screen: string.isRequired,
};
