import Img1 from '../images/raid_forum_theme_1.jpg';
import Img2 from '../images/raid_forum_theme_2.jpg';
import Img3 from '../images/raid_forum_theme_3.jpg';

export default {
  id: 'raid-forum-theme',
  category: 'Web Design',
  title: 'Raid Gaming Forum Theme',
  description: [
    "A responsive custom theme for the Raid Gaming community. The theme makes use of the forum software IP Board 4's theming engine and is written in modern HTML5 and CSS3.",
  ],
  thumb: Img2,
  images: [Img1, Img2, Img3],
  links: { demo: 'https://raid-gaming.net' },
};
