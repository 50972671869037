import Img1 from '../images/scripting_api_1.jpg';
import Img2 from '../images/scripting_api_2.jpg';
import Img3 from '../images/scripting_api_3.jpg';

export default {
  id: 'scripting-api',
  category: 'Web Development',
  title: 'Scripting API',
  description: [
    'A Single-page Application (SPA) written in Vue 3 that allows developers for Call of Duty 4 and 5 to easily look up any functions they need.',
    'The application makes use of a JSON API to retrieve all the data necessary for it to function. With a design optimised for mobile use and support for PWAs, you can even look up documentation on the go!',
    'Source code has been made available so anyone can easily build their own documentation suite out of it.',
  ],
  thumb: Img2,
  images: [Img1, Img2, Img3],
  links: {
    github: 'https://github.com/Raid-Gaming/script-reference',
    demo: 'https://docs.raid-gaming.net',
  },
};
