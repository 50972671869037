import RaidServers from './raid-servers';
import ScriptingAPI from './scripting-api';
import RaidForumTheme from './raid-forum-theme';
import CoD4Unleashed from './cod4-unleashed';
import CoD4Surf from './cod4-surf';
import WaWRiseV2 from './waw-risev2';

export default {
  [RaidServers.id]: RaidServers,
  [ScriptingAPI.id]: ScriptingAPI,
  [RaidForumTheme.id]: RaidForumTheme,
  [CoD4Unleashed.id]: CoD4Unleashed,
  [CoD4Surf.id]: CoD4Surf,
  [WaWRiseV2.id]: WaWRiseV2,
};
